<template>
  <div class="outerDiv" :style="configStyle">
        <header v-if="showHeader">
            <div class="horizFlexStretch">
                <div class="horizFlex">
                    <img alt="school logo" :src="schoolLogoFilePath"  class="logo" />
                    <!-- <label class="titleText">Reports</label> -->
                </div>
                
                <div class="vertFlex rightjustified" v-if="loggedIn">
                    <label class="textmedium">{{loggedInUser.Name}}</label>
                    <linkbutton toplabel="Logout" @click="loggedIn=false" class="textmedium"/>
                </div>
            </div>
        </header>
        
        <loginuser @loggedin='ready()' ref="login" v-if="!loggedIn"/>
        <div v-if="loggedIn" class="reportdiv">
            <report/>     
        </div>

  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import style from '../style.js'
import datamethods from '../datamethods'
import loginuser from './login/loginuser.vue'
import report from './report/report.vue'
import linkbutton from './controls/linkbutton.vue'
export default {
    components:{
        loginuser,
        report,
        linkbutton
    },
    mixins:[style, datamethods],
    computed:{

        ...mapFields([
            'loggedIn',
            'loggedInUser',
            'currentNav',
            'badgeData',
            'logoOverride',
            'configOverride',
            'showHeader'
        ]),
        schoolLogoFilePath(){
            if(this.logoOverride!=''){
                return  this.imageBasePath + this.logoOverride
            }else{
                return './../' + window.SCHOOL_LOGO
            }
            
        },
       
    },
    data(){
        return{
   

            //url
            BadgeGlobalKey:'',
            EmailAddress:'',
            LocationGlobalKey:'',
            PersonGlobalKey:'',


            //config
            institutionGlobalKey:window.INSTITUTION_KEY,
            
            
            frontLinkText:window.FRONT_LINK_TEXT,
            backLinkText:window.BACK_LINK_TEXT,

            ecommerceURL:window.URL_ECOMMERCE,
            validationURL:window.URL_VALIDATION,
            mobileidURL:window.URL_MOBILEID,

            //flags
            
            imageBasePath:'',

            message:'',
            showingValidator:false,

            //data
            
        }
    },
    methods:{

        async getImageBasePath(){
            //call api
            var response = await this.callAPI(null,'imagepath')
            //handle response
            if(response.Successful){
                this.imageBasePath =  response.StringResponse
            }else{
                this.message = response.Message
            }
        },
        imagePath(imagePath){
            return this.imageBasePath + imagePath;
        },

        ready(){
            this.currentNav="new"
            
        },

        autoClear(){
            this.message = ''
        }
    },
    created(){
        const timer = setInterval(()=>{
            this.autoClear();
            },10000);
        
        this.$once("hook:beforeDestroy",()=>{
        clearInterval(timer);
        });
    },
    mounted(){
        
        let urlParams = new URLSearchParams(window.location.search)
     
        let tmp = urlParams.get('logo')
        if(tmp!=null){ this.logoOverride = tmp;}

        this.configOverride = window.VUE_CONFIG_ReportConfigOverride
        this.setColors();
        this.getImageBasePath();
        
        tmp = urlParams.get('header')
        if(tmp!=null){this.showHeader=tmp=='true'; }

        tmp = urlParams.get('token')
        if(tmp!=null){
            console.log('sso!')
            this.$refs.login.post_login(tmp)
         }

        if(window.REQUIRE_LOGIN==false){
            this.loggedIn=true
        }else{
            this.$refs.login.prelogin()
        }
        
    }
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";

body{
  height:100vh; 
  height: -webkit-fill-available;
  width:100vw;
  padding:0px;
  margin:0px;
  font-size:x-large;
  color:var(--text-color);
}
.outerDiv{
  margin:0px;
  padding:0px;
  height:98vh;
}
.frontDiv{
    height:100vh;
    display:grid;
    grid-template-columns:auto;
    grid-template-rows:12% 15% 36% 10% 15% 12%;
}
.backDiv{
    height:100vh;
    display:grid;
    grid-template-columns:auto;
    grid-template-rows:12% 15% 46% 15% 12%;
}

.titleText{
    font-weight: bold;
    margin-left:10px;
}

/* HEADER */
header{
    background-color:var(--highlight-color);
    padding:10px;
}
.logo{
    height:60px;
}
.logo2{
    height:100px;
    margin:20px;
}


/* ALERT */
.alert{
    padding:10px;
}
.alertdiv{
    background-color: var(--alert-color);
    padding:10px;
}
.alerttext{
    color:var(--light-text-color);
    font-weight: bold;
}

/* STUDENT */
.studentimage{
    height:160px;
    margin:5px;
}
.studentinfo{
    width:100%;
    text-align: center;
}
.studentname{
    font-size:xx-large;
    font-weight:bolder;
    margin:5px 5px 5px 0px;
}

.studentclass{
    margin:10px;
}

/* STATUS */
.status{
    padding:20px;
}
.statustext{
    font-weight: bold;
    color:var(--alert-color);
}

/* BARCODE */
.barcode{
    padding:5px;
}

/* TOGGLE */
.toggle{
    background-color:var(--highlight-color);
    padding:10px;
}


/* INFO */
.info{
    text-align: left;
    padding:20px;
}
.infoset{
    display:flex;
    flex-direction: column;
    margin-bottom:25px;
}
.infobig{
    font-size:large;
    font-weight:bold;
}
.infolink{
    font-size:medium;
    text-decoration: underline;
}

.demobutton{
    color:white;
    background-color:rgb(24, 24, 207);
    border: none;
    border-radius:10px;
    width:215px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin: 0px 10px 0px 10px;
  text-decoration: none;
  white-space: pre;
}
.buttondiv{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.textmedium{
    font-size: medium;
}
.reportdiv{
    margin:10px;
    height:90%;
}
</style>



            