<template class="fullheight">
<div class="mainLayout fullheight">
    
    <div class="lookup ">
        <div class="vertFlexLeft">
          <label class="titlelabel">Reports</label>
        <select name="" id="" class='lookupSelect' v-model="SelectedLocation"  @change="lookup">
            <option v-for="location in LocationList" :key="location.ID" :value="location.ID">
                {{location.Name}}
            </option>
        </select>   
        
        <div class="lookupdiv">
            <div class="scrollDivResults" v-if="lookupResults.length>0">
                <div v-for="result in lookupResults" :key="result.ID" :value="result.ID" >
                    <lookupresult :topLeft="result.Name"    @click="getReport(result)"
                        :selected="result.selected">
                   
                    </lookupresult>  
                </div>
            </div>
            <div class="paramdiv">
                <div class="vertFlex" v-for="param in userParameters" :key="param.ID" >
                    <p class="editLabel">{{param.Name}}</p>
                    <input class="editText" :type="paramInputType(param)" v-if="!paramIsSelect(param)" v-model="param.CurrentValue"/>
                    <select name="" id="" class='lookupSelect'  v-if="paramIsSelect(param)" v-model="param.CurrentValue">
                        <option v-for="opt in param.Values" :key="opt.ID" :value="opt.ID" >
                            {{opt.Name}}
                        </option>
                    </select>
                </div>
                
            </div>
            <div class="pleasewait" v-show="pleaseWait">
                <label for="">Please Wait...</label>
            </div>
            <div class="responsemessage">
                <label for="">{{errorMessage}}</label>
            </div>
            <div class="reportButtonDiv horizFlex" v-if="!pleaseWait">
                <smallbutton toplabel="View" v-on:click="generateReport()" ></smallbutton>
                <reportscheduler/>
            </div>
        </div>       
        </div>
                
        
            
    </div>
        
        
    <div class="main">
        <div class="reportView ">
            <div class="horizFlexStretch" v-if="!showEmail">
                <p class="titlelabel">{{selectedResult.Name}}</p>
                <div class="horizFlex">
                    <confirm :showButton="paramTrue" :title="ReportActionButtonText" :body="reportActionBodyText" @yes="reportAction" v-if="reportURL!='' && selectedResult.Name==ReportActionReportName"/>
                    <!-- <smallbutton :toplabel="ReportActionButtonText" v-on:click="reportAction()" v-if="reportURL!='' && selectedResult.Name==ReportActionReportName"></smallbutton> -->
                    <a :href="reportURL" target="_blank" class="reportLink" v-if="reportURL!=''">
                        <smallbutton toplabel="Download PDF" class="emailbutton" ></smallbutton>
                    </a>
                    <a :href="excelURL" target="_blank" class="reportLink" v-if="excelURL!=''">
                        <smallbutton toplabel="Download Excel" ></smallbutton>
                    </a>
                    <smallbutton toplabel="Send Email" v-on:click="emailReport()" v-if="!pleaseWait"></smallbutton>
                </div>
            </div>
            <div class="vertFlex leftjustified left10" v-if="showEmail">
                
                <p class="titlelabel">Send Email</p>
                <formentrystacked label="Subject:" v-model="emailSubject"/>
                
                <div class="margin10">
                    <div class="horizFlex">
                        <label>Recipients </label>
                        
                    </div>
                    
                    <div class="vertFlex leftindent">
                        <div v-for="recipient in recipientList" :key="recipient.ID" :value="recipient.ID">
                            <input type="checkbox" v-model="recipient.selected" @change="selectRecip(recipient)"/>
                            <label >
                                {{recipient.Name}} ({{recipient.EmailAddress}})
                            </label>
                        </div>
                        <addrecipient @refresh="getEmailRecipientList"/>
                    </div>
                
                </div>
                
                <!-- <formentrystacked label="Send to the following email addresses (separate multiple by ;):" v-model="emailToAddresses"/> -->

                <div class="horizFlex emailRadioDiv">
                    
                    <input type="radio" class="emailRadio" value="pdf" v-model="emailReportType" name="reporttype">
                    <p class="emailRadioLabel"  >PDF</p>
                
                    <input type="radio" class="emailRadio" value="csv" v-model="emailReportType"  name="reporttype">
                    <p class="emailRadioLabel" >CSV</p>
                       
                </div>
                <div class="horizFlex">
                    <smallbutton toplabel="Send" v-on:click="sendEmailReport()" ></smallbutton>
                    <smallbutton toplabel="Cancel" v-on:click="showEmail=false;" ></smallbutton>
                </div>
                
            </div>
            <div class="emailPanel horizFlex left10" v-if="showEmailConfirmation">
                <p class="innerTitle adminList">Email Sent Successfully</p>
                <smallbutton toplabel="Close" v-on:click="closeEmailConfirm()" ></smallbutton>
            </div>
            <div class="scrollDivReport">
                <iframe :src="reportURL" frameborder="0" height="100%" width="100%" class="reportFrame">
                </iframe>
            </div>
            
            <!-- <object :data="reportURL" type="application/pdf">
            <embed :src="reportURL" height="200" width="200" type="application/pdf"/>
            </object> -->
        </div>
    </div>
        
</div>

</template>

<script>
import {mapFields} from 'vuex-map-fields'
import entrySelect from '../controls/entry-select.vue'
import datamethodsreport from '../../datamethods-report.js'
import formentry from '../controls/entry.vue'
import smallbutton from '../controls/button.vue'
import formentrystacked from '../controls/entry-stacked.vue'
import lookupresult from '../controls/lookupresult.vue'
import reportscheduler from './ReportScheduler.vue'
import confirm from '../actions/confirm.vue'
import addrecipient from './addRecipient.vue'
export default {
    name:'walletreport',
    components:{
        entrySelect,
        smallbutton,
        formentry,
        formentrystacked,
        lookupresult,
        reportscheduler,
        confirm,
        addrecipient
    },
    mixins:[datamethodsreport],
    data(){
        return{
            LocationList:[],
            SelectedLocation:-1,

            pleaseWait:false,
            imageBasePath:'',

            selection:{},

            lookupResults:{},
            selectedResult:{},
            instance:{},
            reportURL:'',
            excelURL:'',
            showEmail:false,
            showEmailConfirmation:false,
            emailSubject:'Please see attached report',
            emailToAddresses:'',
            emailPDF:true,
            emailCSV:false,
            emailReportType:'pdf',
            errorMessage:'',
            testresp:{},
            paramTrue:true,
            message:null,
            recipientList:[],


        }
    },
    computed:{
        userParameters(){    
            let tempUsers = this.selectedResult.Parameters
            if(tempUsers!=undefined){
                tempUsers = tempUsers.filter((item)=>{
                    return (item.UserMayEnter)
                })
                return tempUsers
            }
                
        },
        ...mapFields([
      
            'configOverride'
        ]),
        ReportActionReportName(){
            if(window.REPORT_ACTION_REPORTNAME==undefined){return ''}
            else{return window.REPORT_ACTION_REPORTNAME}
        },
        ReportActionButtonText(){
            if(window.REPORT_ACTION_BUTTONTEXT==undefined){return ''}
            else{return window.REPORT_ACTION_BUTTONTEXT}
        },
        reportActionBodyText(){
            return "Do you want to " + this.ReportActionButtonText + "?"
        },
        emailRecipients(){
            var email = null
            this.recipientList.forEach(myFunction2)

            function myFunction2(item, index, arr) {
                let e=arr[index]
                if(e.selected){
                    if(email==null){
                        email=e.EmailAddress
                    }else{
                        email=email + ';' + e.EmailAddress
                    }
                }
            } 
            return email
        }
    },
    methods:{
        beforeAPI(){
            this.pleaseWait=true
            this.errorMessage=''
        },
        paramInputType(param){
            switch(param.ControlType){
                case 3:
                    return "date"
                    break;
                
                default:
                    return "text"
                    break;
            }

        },
        paramIsSelect(param){
            if(param.ControlType=="2"){
                return true;
            }else{
                return false
            }
        },
        reportButtonClass(reportID){
            if(reportID==this.selectedResult.ID){
                
                return "adminButtonSelected"
            }else{
                return "adminButton"
            }    
        },
        async getImageBasePath(){
            console.log('img')
            //call api
            this.beforeAPI();
            var response = await this.callAPI(null,'reportimagepath')
            //handle response
            if(response.Successful){
                
                this.imageBasePath = window.VUE_CONFIG_ReportAPI + response.StringResponse
                console.log('img' + this.imageBasePath)
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async GetLocations(){
     
            //call api
            this.beforeAPI();
            var response = await this.callAPI(null,'locations')
            //handle response
            
            if(response.Successful){
                this.LocationList = response.LocationList
                this.SelectedLocation=this.LocationList[0].ID
                this.lookup()
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async lookup(){
            console.log('lookup')
            //setup request
            let req = {
                ReportTypeID:-1,
                SalonID:this.SelectedLocation
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'reportlist')
            //handle response
            
            if(response.Successful){
                
                this.lookupResults = response.ReportList
                if(this.selected!=null){
                    this.getReport(this.selected)
                }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        clearselect(){
            this.lookupResults.forEach(myFunction2)

            function myFunction2(item, index, arr) {
                let acct=arr[index]
                acct.selected=false;
            } 
        },
        async getReport(report){
            this.selected=report;
            this.clearselect();
            report.selected=true
            //setup request
            let req = {
                ReportID:report.ID,
                SalonID:this.SelectedLocation
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'report')
            
            //handle response
            if(response.Successful){
                console.log('success')
                this.selectedResult = response.Report
                this.setDates();
                this.isSelectedResult=true;
                this.reportURL=''
                this.excelURL=''
            }else{
                console.log('fail')
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        },
        async generateReport(){
            this.errorMessage =''
            this.reportUrl=''
            this.showEmailConfirmation=false;
            this.showEmail=false;

            //setup request
            let req = {
                Report:this.selectedResult
            }
            //call api
            
            this.beforeAPI();
            var response = await this.callAPI(req,'generate')
            this.testresp=response
            //handle response
            if(response.Successful){
                if(response.Instances==null){
                    console.log('no instance')
                    this.generateExcelFirst()
                    return;
                }

                this.instance = response.Instances[0]
                    //this.reportURL = 'https://docs.google.com/viewerng/viewer?url='+this.imageBasePath + response.data.Instances[0].ExportPath+'&embedded=true'
                    this.reportURL = this.imageBasePath + response.Instances[0].ExportPath
                    this.isSelectedResult=true;
                    this.generateExcel();
            }else{
                this.errorMessage = "Report Failed :" + response.Message
                
            }
            //this.pleaseWait=false;

          
        },
        async generateExcelFirst(){
           //setup request
            let req = {
                Report:this.selectedResult
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'excelfirst')
            //handle response
            //this.testresp=response
            if(response.Successful){
                this.excelURL = this.imageBasePath + response.StringResponse
                this.isSelectedResult=true;
            }else{
                this.errorMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async generateExcel(){
            this.excelURL=''
           //setup request
            let req = {
                ReportInstance:this.instance
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'excel')
            //handle response
            //this.testresp=response
            if(response.Successful){
                this.excelURL = this.imageBasePath + response.StringResponse
                this.isSelectedResult=true;
            }else{
                this.errorMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        emailReport(){
            this.showEmail=true;
        },
        async sendEmailReport(){
            //determine which report to take
            var urlToSend=''
            if(this.emailReportType=="pdf"){
                urlToSend=this.reportURL
            }else{
                urlToSend=this.excelURL
            }

            //setup request
            let req = {
                subject:this.emailSubject,
                message:this.emailSubject,
                toAddresses:this.emailRecipients,
                attachPath:urlToSend
            }
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'email')
            //this.testresp=response
            //handle response
            if(response.Successful){
                this.showEmail=false;
                this.showEmailConfirmation=true;
            }else{
                this.errorMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        closeEmailConfirm(){
            this.showEmailConfirmation=false;
        },
        //dates!!!
        setDates(){
            console.log('setdates')
            this.selectedResult.Parameters.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let param=arr[index]
                console.log('controltype ' + param.ControlType)
                if(param.ControlType==3){
                    console.log(param.CurrentValue)

                    var t= new Date(param.CurrentValue);

                    var dd = String(t.getDate()).padStart(2, '0');
                    var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = t.getFullYear();

                    param.CurrentValue=yyyy + '-' + mm + '-' + dd
                }

            } 
        },
        formatDateForInputToday(){

            var t= new Date();

            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = t.getFullYear();

            return yyyy + '-' + mm + '-' + dd
        },
        formatDateForInput(dateString){
            console.log('date ' + datestring)
            var t= new Date(dateString);

            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = t.getFullYear();

            return yyyy + '-' + mm + '-' + dd
        },
        async reportAction(){
             //setup request
             let req = {
                Report:this.selectedResult
            }
            console.log(req)
            //call api
            this.beforeAPI();
            var response = await this.callAPI(req,'reportaction')
            //handle response
            if(response.Successful){
                this.errorMessage = this.ReportActionButtonText + " was successful."
            }else{
                this.errorMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getEmailRecipientList(){
             let req = {
                SalonID:this.locationID,
            }

            this.beforeAPI();
            var response = await this.callAPI(req,'emailrecipientlist')
            //handle response
            if(response.Successful){
                this.recipientList = response.EmailRecipientList
                
                this.pleaseWait=false;
            }else{
                this.errorMessage = response.Message
            }
            this.pleaseWait=false; 



           
            
        },
    },
    
    mounted(){
        this.AddConfigToAPI(this.configOverride);
        this.GetLocations()
        //this.lookup();
        this.getImageBasePath()
        this.getEmailRecipientList()
    }
}
</script>

<style>
.mainLayout{
    
    font-size:large;
    display:grid;
    grid-template-columns:1fr 3fr;
    grid-template-rows:auto;
    grid-template-areas: 
    "lookup main";
}
.lookup{
    grid-area:lookup;
}
.main{
    grid-area:main;   
}
.lookupResults{
    padding:0px;
    margin-top:10px;
}
.scrollDivResults{
    height:440px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.inner{
    border-radius:10px; 
    margin:10px;
    padding:0px;
    text-align: left;
    background-color: white;
}
.lookupdiv{
    height:750px;
}
.paramdiv{
    margin-top:20px;
}
.reportButtonDiv{
    margin-top:10px;
}
.reportView{
    height:100%;
}
.scrollDivReport{
    height:650px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.editLabel{
    margin-left:10px;
    width:200px;
    margin-top:0px;
    margin-bottom:0px;
}
.editText{
    height:20px;
    margin-top:0px;
    margin-left:10px;
    margin-bottom:10px;
    width:90%;
    font-style:inherit;
    font-family:inherit;
    font-size:inherit;
}
.lookupSelect{
    width:92%;
    margin-left:10px;
    font-style:inherit;
    font-family:inherit;
    font-size:inherit;
    margin-bottom:10px;
}
.adminButton{
    grid-area:add;
    background-color:rgb(238,120,45);
   color:white;
   padding:10px;
   border-radius:20px;
   border:none;
   font-size:inherit;
   width: 200px;
   height:50px;
   margin-top:10px;
   margin-left:10px;

}
.adminButtonSelected{
    grid-area:add;
    background-color:rgb(196, 218, 226);
   color:rgb(0,158,192);
   padding:10px;
   border-radius:20px;
   border:solid;
   border-color:rgb(238,120,45);
   font-size:inherit;
   width: 200px;
   height:50px;
   margin-top:10px;
   margin-left:10px;
    font-weight:bold;
}

</style>